import request from '@/utils/request'

//获取活动数据
export const getActivity = (insNo) =>
  request({
    url: '/v1/screen/ins/activity?insNo=' + insNo,
    method: 'get',
    type: 'ins',
  })

//获取机构内社区养老服务信息
export const getAggregate = (insNo) =>
  request({
    url: '/v1/screen/ins/aggregate?insNo=' + insNo,
    method: 'get',
    type: 'ins',
  })

//获取总床位护理床位
export const getBed = (insNo) =>
  request({
    url: '/v1/screen/ins/bed?insNo=' + insNo,
    method: 'get',
    type: 'ins',
  })

//居家服务实时数据
export const getRelatime = (insNo) =>
  request({
    url: '/v1/screen/ins/realtime_data?insNo=' + insNo,
    method: 'get',
    type: 'ins',
  })

// 统计数据集合
export const statisticalDataCollection = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/mock-data?level=2',
    method: 'get',
    params: data,
  })

// 社区活动开展情况
export const getActivityList = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/ins/activities',
    method: 'get',
    params: {
      institutionNo: data,
    },
  })
